import React from 'react';
import { graphql } from 'gatsby';

import withI18next from '../../i18n/TemplateI18n';
import Header from '../../components/BlogHeader';
import Body from '../../components/BlogBody';
import Layout from '../../components/atoms/layout';
import Footer from '../../components/Footer';
import NavBar from '../../components/NavBar';
import ContextualCta from '../../components/ContextualCta';
import { datoCategoryToWebUrl } from '../../../build-utils/dato-utils';
import Wrapper from './styles';
import { BlogArticleProps } from '../../interface/template';
import BlogArticlesSample from '../../components/BlogArticlesSample';
import GetUpdatesForm from '../../components/BlogGetUpdatesForm';
import SEOBlog from '../../components/SeoBlog';
import Banner from '../../components/Banner';
import useUserLocation from '../../hooks/useUserLocation';

const BlogArticle: React.SFC<any> = (props: BlogArticleProps) => {
  const { locale, article, hreflangs } = props.pageContext;
  const seoPage = `${datoCategoryToWebUrl(
    article.mainCategory.code
  )}/:last-breadcrumb`;
  const { userLocation } = useUserLocation();

  return (
    <>
      {userLocation?.country === 'NL' && <Banner type="stap" />}
      <SEOBlog
        hreflangs={hreflangs}
        locale={locale}
        {...article}
        {...article.seoInfo}
      />
      <Wrapper>
        <NavBar className="article-menu" showLanguages={false} />
        <Layout className="article-layout">
          <Header {...article} />
          <Body {...article} />
        </Layout>
        <BlogArticlesSample
          articleId={article.id}
          category={article.mainCategory.code}
        />
        <GetUpdatesForm />
        <Footer lastBreadcrumb={article.title} page={seoPage} />
      </Wrapper>
    </>
  );
};
export default withI18next()(BlogArticle);

export const query = graphql`
  query ($locale: String!) {
    locales: allLocale(
      filter: {
        lng: { eq: $locale }
        ns: {
          regex: "/(menu)|(eyebrow)|(breadcrumbs)|(contextual-cta)|(seo)|(course-list)|(blog)|(banner)|(application-form)|(request-syllabus)/"
        }
      }
    ) {
      ...TranslationFragment
    }
  }
`;
